import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Scroll from "../atoms/scroll";
import { useScrollPosition } from "../../utils/useScrollPosition";

export default ({ theme }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const [headerStyle, setHeaderStyle] = useState({});

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setIsOpen(false);
      const shouldFloat = currPos.y < -80 && currPos.y > prevPos.y;
      const isVisible = currPos.y > -160 || currPos.y > prevPos.y;
      setHeaderStyle({
        color: shouldFloat ? "#000000" : "#ffffff",
        backgroundColor: shouldFloat ? "rgba(0,0,0,0.75)" : "transparent",
        transition: `all 200ms ${shouldFloat ? "ease-in" : "ease-out"}`,
        // transform: shouldVisible ? "none" : "translate(0, -100%)",
        position: shouldFloat ? "fixed" : "relative",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1030,
      });

      // if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;
    },
    [headerStyle, setIsOpen]
  );

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      style={{ ...headerStyle }}
      className={isOpen ? "bg-white ___fixed-top" : "___fixed-top"}
    >
      <div className="container">
        <nav className="navbar navbar-light navbar-expand-lg">
          <Link class="navbar-brand" to="/">
            <Img
              fixed={data.logo.childImageSharp.fixed}
              style={{ height: "64px" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={(isOpen ? "show" : "") + " collapse navbar-collapse"}
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto text-uppercase serif font-weight-bold">
              <li className="nav-item">
                <Scroll type="id" element="usp" offset={-60}>
                  <a
                    className={
                      isOpen ? "nav-link text-white" : "nav-link text-white"
                    }
                    href="#usp"
                  >
                    Why Choose Us
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll type="id" element="about-us" offset={-60}>
                  <a className={isOpen ? "nav-link text-black" : "nav-link text-white"} href="#about">
                    About Us
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll type="id" element="our-services" offset={-60}>
                  <a className={isOpen ? "nav-link text-black" : "nav-link text-white"} href="#service">
                    Our Service
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll type="id" element="contact-us" offset={-60}>
                  <a className={isOpen ? "nav-link text-black" : "nav-link text-white"} href="#contact">
                    Contact
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll type="id" element="faq" offset={-60}>
                  <a className={isOpen ? "nav-link text-black" : "nav-link text-white"} href="#faq">
                    FAQ
                  </a>
                </Scroll>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};
