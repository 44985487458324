import React from "react";
import Modal from "react-modal";

export default function ServiceModal(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          padding: 0,
          borderRadius: 0,
          marginRight: "-50%",
          border: 0,
          backgroundColor: "#ffffff",
          color: "#000000",
          transform: "translate(-50%, -50%)",
          // maxWidth: "400px",
        },
        overlay: {
          backgroundColor: "rgba(0,0,0,0.9)",
          zIndex: 999,
        },
      }}
    >
      <div class="p-md-5">
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-md-6">
              <div style={{ Width: "400px", height: "auto" }}>{props.image}</div>
            </div>
            <div class="col-12 col-md-6">
              <div style={{ padding: "24px 16px" }}>
                <h4 className="h5">{props.title}</h4>
                <div className="p">{props.body}</div>
                <a class="btn btn-link text-dark small mt-3" onClick={() => props.onRequestClose()}>
                  Close now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
