import React from "react";

export default function FAQList() {
  return (
    <dl id="faq-list">
      <FAQ q="What are the requirements to form a private limited company (Sdn. Bhd.) in Malaysia?">
        <p>
          The company needs to have at least one (1) persons as the company
          director and fulfill the condition as below:
        </p>
        <ul>
          <li>
            Ordinarily reside in Malaysia by having a principal place of
            residence in Malaysia.
          </li>
          <li>Above 18 years of age.</li>
          <li>Not an undischarged bankrupt.</li>
          <li>
            Has not been convicted of a serious offence within the past 5 years.
          </li>
        </ul>
      </FAQ>
      <FAQ q="How long will it take to incorporate a private limited company (Sdn. Bhd.) in Malaysia?">
        The company can normally be set up within three (3) to ten (10) working
        days.
      </FAQ>
      <FAQ q="Can foreigner incorporate a private limited company (Sdn. Bhd.) in Malaysia without Malaysian involvement?">
        Yes. Foreigners can set up a 100% foreign holding company in Malaysia
        without Malaysian involved. However please take note that according to
        Companies Act 2016 Section 196, every company shall have at least one
        (1) director (He can be Malaysian or foreigner), who shall ordinarily
        reside in Malaysia by having a principal place of residence in Malaysia.
      </FAQ>
      <FAQ q="How can I terminate a private limited company (Sdn. Bhd.)?">
        <p>
          A private limited company (Sdn. Bhd.) can be terminated by the
          following methods:
        </p>
        <ul>
          <li>Voluntary winding up</li>
          <li>Strike off under Section 549 of Companies Act 2016</li>
          <li>Winding up by court order</li>
        </ul>
      </FAQ>
      <FAQ q="Can a private limited company (Sdn. Bhd.) shares hold by another company (e.g. holding company)?">
        Yes
      </FAQ>
      <FAQ q="Will the director or company be liable after the company striked off?">
        Any person who is aggrieved may apply to the Court to reinstate the name
        within seven (7) years after the name of the company has been strike off
        under Companies Act 2016 Section 555 (1)
      </FAQ>
    </dl>
  );
}

function FAQ(props) {
  return (
    <>
      <dt>{props.q}</dt>
      <dd>{props.children}</dd>
    </>
  );
}
