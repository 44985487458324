import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const nameState = useState("");
  const emailState = useState("");
  const phoneState = useState("");
  const msgState = useState("");
  const recaptchaState = useState("");

  const actions = {
    handleSubmit: (e) => {
      e.preventDefault();

      if (nameState[0] === "" || emailState[0] === "" || phoneState[0] === "" || msgState[0] === "") {
        alert("Please complete all fields to submit");
        return;
      } else if (recaptchaState[0] === "") {
        alert("Please tick reCAPTCHA to submit");
        return;
      }

      setLoading(true);

      axios
        .post("https://email.api.innovten.com/v1/email", {
          apiKey: "2102236LfH897ADCT70vuBS1U",
          gRecaptcha: recaptchaState[0],
          data: {
            name: nameState[0],
            email: emailState[0],
            phone: phoneState[0],
            msg: msgState[0],
          },
        })
        .then((response) => {
          alert("We have received your message and will reply you as soon as possible");
          setLoading(false);
          nameState[1]("");
          emailState[1]("");
          phoneState[1]("");
          msgState[1]("");
        })
        .catch((error) => {
          alert("Failed to submit");
          setLoading(false);
        });
    },
  };

  return (
    <form id="contact-form">
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="ipName"
          autoComplete="name"
          placeholder="Your name"
          disabled={loading}
          value={nameState[0]}
          onChange={(e) => nameState[1](e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          id="ipEmail"
          autoComplete="email"
          placeholder="Your email address"
          disabled={loading}
          value={emailState[0]}
          onChange={(e) => emailState[1](e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="ipContact"
          autoComplete="tel"
          placeholder="Your contact number"
          disabled={loading}
          value={phoneState[0]}
          onChange={(e) => phoneState[1](e.target.value)}
        />
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          id="ipMsg"
          rows="3"
          placeholder="Message"
          disabled={loading}
          value={msgState[0]}
          onChange={(e) => msgState[1](e.target.value)}
        ></textarea>
      </div>
      <div className="form-group">
        <ReCAPTCHA sitekey="6LfPJWQaAAAAAKu32sdu8YXOOL2LD_rE8liB3nF-" onChange={recaptchaState[1]} />
      </div>
      <button type="submit" class="btn btn-black btn-block" disabled={loading} onClick={actions.handleSubmit}>
        {!loading && "Send a message"}
        {loading && "Submitting..."}
      </button>
    </form>
  );
}
