import React from "react";
import lodash from "lodash";
import Img from "gatsby-image";

export default function ImageComponetInit(data) {
  return function ImageComponent(props) {
    return (
      <Img
        style={props.style}
        fluid={lodash.get(data, `${props.source}.childImageSharp.fluid`, null)}
        fixed={lodash.get(data, `${props.source}.childImageSharp.fixed`, null)}
        loading={props.loading}
        fadeIn={props.fadeIn}
        durationFadeIn={props.durationFadeIn}
      />
    );
  };
}
