import React from "react";

export default function ContactInfo() {
  return (
    <div class="pt-md-5">
      <dl class="serif">
        <dt>Office Address</dt>
        <dd class="p mb-3">
          <a
            class="text-dark"
            target="_blank"
            rel="noreferrer"
            href="https://goo.gl/maps/BPKVCidNr4tVjsf16"
          >
            No. 38A, Jalan Beladau 3, Taman Puteri Wangsa, 81800 Ulu Tiram,
            Johor, Malaysia.
          </a>
        </dd>

        <dt>Business Hours</dt>
        <dd class="p mb-3">
          8:30am - 5:30pm (Mon - Fri, excluding Public Holiday)
        </dd>

        <dt>Email</dt>
        <dd class="p mb-3">
          <a class="text-dark" href="mailto:staff@wtlim.com.my">
            staff@wtlim.com.my
          </a>
          <br />
          <a class="text-dark" href="mailto:admin@wtlim.com.my">
            admin@wtlim.com.my
          </a>
        </dd>

        <dt>Contact Number</dt>
        <dd class="p mb-3">
          <span>Tel: </span>
          <a class="text-dark" href="tel:6078671089">
            +607 867 1089
          </a>
          {"  /  "}
          <a class="text-dark" href="tel:601115049789">
            +6011 1504 9789
          </a>
          <br />
          <span>Fax: </span>
          <a class="text-dark" href="fax:6078671089">
            +607 867 1089
          </a>
        </dd>
      </dl>
    </div>
  );
}
