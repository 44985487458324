import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";

import ContactForm from "../components/organisms/contactForm";
import ContactInfo from "../components/organisms/contactInfo";
import CSRList from "../components/organisms/csrList";
import FAQList from "../components/organisms/faqList";
import Navbar from "../components/organisms/navbar";
import ServiceModal from "../components/organisms/serviceModal";
import BgImgContactUs from "../images/bgimg-contactus.jpg";
import { Scroll, ImageInit } from "../components/atoms";

export default function IndexPage({ data }) {
  const [modal, setModal] = React.useState(false);
  const Image = ImageInit(data);
  const Service = (props) => {
    return (
      <>
        <div class="col-md-4 col-12 bg-dark">
          <div class="photo-box">
            <a
              onClick={() => {
                setModal({
                  source: props.source,
                  title: props.title,
                  body: props.children,
                });
                return false;
              }}
            ></a>
            <Image source={props.source} />
            <div class="box-overlay"></div>
            <div class="box-content text-white">
              <span class="h4 serif">{props.title}</span>
              <br />
              <span class="small" style={{ opacity: "0.25" }}>
                Click to know more
              </span>
            </div>
            <div class="masking">
              <div class="uabb-inner-mask"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Company Secretary Johor Bahru | WT Lim & CO</title>
        <meta
          name="description"
          content="Company Secretary service based in Johor Bahru, Malaysia. Corporate professional and accountancy services in market for more than 10 years"
        />
        <meta name="keywords" content="Company Secretary Johor Bahru" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Company Secretary Johor Bahru | WT Lim & CO"
        />
        <meta
          property="og:description"
          content="Company Secretary service based in Johor Bahru, Malaysia. Corporate professional and accountancy services in market for more than 10 years"
        />
        <meta property="og:url" content="https://wtlim.com.my" />
        <meta property="og:site_name" content="WT Lim & Co" />
        <meta property="og:image" content="https://wtlim.com.my/img/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Company Secretary Johor Bahru" />
        <meta
          name="twitter:description"
          content="Company Secretary service based in Johor Bahru, Malaysia. Corporate professional and accountancy services in market for more than 10 years"
        />
        <meta
          name="twitter:image"
          content="https://wtlim.com.my/img/logo.png"
        />

        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://wtlim.com.my",
          "name": "WT Lim & Co",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+6078671089",
            "contactType": "Sales"
          }
        }
      `}
        </script>
      </Helmet>
      <main>
        <div class="bg-black py-2">
          <div class="container">
            <div class="row">
              <div class="col">
                <span>
                  <a href="tel:+6078671089" class="text-white">
                    +6078671089
                  </a>
                  {" | "}
                  <a href="tel:+601115049789" class="text-white">
                    +601115049789
                  </a>
                </span>
                <span class="pl-2">
                  <a href="mailto:staff@wtlim.com.my" class="text-white">
                    staff@wtlim.com.my
                  </a>
                  {" | "}
                  <a href="mailto:admin@wtlim.com.my" class="text-white">
                    admin@wtlim.com.my
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <header id="header" class="bg-hero text-white">
          <div className="hero-content">
            <Navbar />
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="px-3 py-6 text-left text-md-center">
                    <h1 class="serif display-md-3 mb-5">
                      Corporate professional and accountancy services
                    </h1>
                    <div>
                      <Scroll type="id" element="contact-us" offset={-60}>
                        {/* <Link class="btn btn-white px-5 my-2" to="#contact-us"> */}

                        <a class="btn btn-white px-5 my-2" href="#contact-us">
                          Start incorporating
                        </a>
                      </Scroll>
                      <Scroll type="id" element="about-us" offset={-60}>
                        <a
                          class="px-5 text-white d-block d-md-inline my-2 text-uppercase"
                          href="#about-us"
                        >
                          Learn More About Us
                        </a>
                      </Scroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hero-overlay"
            style={{
              backgroundImage:
                "linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.25))",
            }}
          ></div>
          <div className="hero-images">
            <Image
              source="fluidHero"
              style={{ height: "100%", width: "auto" }}
              fadeIn={false}
              loading="eager"
              fadeInDuration={250}
            />
          </div>
        </header>

        <div id="usp" class="bg-black py-3">
          <div class="container">
            <div class="row">
              <UnqiueSellingPointBlock
                title="More than 10 years in the industry"
                details="The Company ‘s founder, Mr. Lim Wei Thu was an auditor in Big 4 Malaysia and Singapore with total experience of more than 5 years. Subsequently, Mr. Lim started his public practice in the industry since 2016 until today."
              />
              <UnqiueSellingPointBlock
                title="Fast response and Cost efficiency"
                details="100% Response Rate within 3 working days. Appoint us as your company secretary or outsource accountant is always more cost efficient."
              />
              <UnqiueSellingPointBlock
                title="Compliance to relevant statutory requirements"
                details="Company's Annual Statutory Compliance Requirements including: Accounting, Financial Statements, Annual General Meeting, filing requiement under Companies Act 2016 Filling of MBRS Financial Statements. Etc.
            "
              />
            </div>
          </div>
        </div>

        <div id="about-us" class="py-6">
          <div class="container-fluid">
            <div class="row">
              <div class="offset-md-1 col-md-6 col-12 py-md-5 order-2 order-md-1">
                <div class="py-5">
                  <h2 class="section-header">About Us</h2>
                  <h3 class="section-subheader serif">
                    Welcome to WT Lim & Co
                  </h3>
                  <p class="p">
                    WT LIM & CO, MIA registered member firm, was founded in year
                    2016 by Mr. Lim Wei Thu. The firm has since progressively
                    expanded its presence throughout Malaysia and transformed
                    into a one-stop professional centre providing innovative,
                    cost-effective professional business services and solutions
                    locally. The firm has also expanded its serviecs to company
                    secretary, accountancy and corporate tax planning.
                  </p>
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <h3 class="section-subheader serif mt-4 small">
                        Mission
                      </h3>
                      <p class="p">
                        To provide simple solution to your complex situation
                      </p>
                    </div>
                    <div class="col-md-6 col-12">
                      <h3 class="section-subheader serif mt-4">Vision</h3>
                      <p class="p">
                        Acknowledge our associates are the firm’s most valuable
                        assets. We will create a culture of pride and passion
                        enabling us to continue attracting and retaining the
                        best and brightest professionals who will be rewarded,
                        recognized and respected for their contributions to the
                        firm.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="offset-md-1 col-md-4 col-12 p-0 order-1 order-md-2">
                <div class="py-5">
                  <Image source="fluidOffice" />
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid p-0">
            <div id="our-services" class="row no-gutters">
              <Service
                key="cosec"
                source="fluidCosec"
                title="Company Secretary Service"
              >
                <p className="small">
                  Company Secretarial Service Ensures The Compliance Of A
                  Company And Provides Advisory Services On The Companies’ Laws
                  And Regulations Under The Malaysia Companies Act 2016.
                  Besides, It Is A Suite Of Services To Clients To Form/setting
                  Up New Companies, Preparation Of Necessary Resolutions,
                  Provides Assistance And Consultations To The Board.
                </p>
                <p className="small m-0">List Of The Secretarial Services:</p>
                <ul className="small">
                  <li>Registry Of A Startup Company</li>
                  <li>
                    Compliance With The Latest Companies Act, And Law And
                    Regulations
                  </li>
                  <li>Prepared Documents For Open Bank Account</li>
                </ul>
              </Service>
              <Service
                key="accounting"
                source="fluidAccounting"
                title="Accounting Service"
              >
                <p className="small m-0">List Of The Accounting Services:</p>
                <ul>
                  <li>
                    Our Services Are To Assist You (The Company's Directors Of A
                    Sendirian Berhad) To Prepare The Management Accounts:
                  </li>
                  <ul>
                    <li>Trial Balances</li>
                    <li>Statement Of Financial Position / Balance Sheet</li>
                    <li>
                      Statement Of Comprehensive Income / Income Statement
                    </li>
                  </ul>
                  <li>Account For Business (Monthly and Yearly)</li>
                </ul>
              </Service>
              <Service
                key="tax"
                source="fluidTax"
                title="Corporate Tax Compliance & Advisory"
              >
                <p className="small">
                  We seek to understand the nature of our clients’ businesses
                  and provide comprehensive tax compliance and advisory to them,
                  regardless of the size and industry in which they operate.
                </p>
                <p className="small m-0">
                  List Of The Corporate Tax Compliance & Advisory Services:
                </p>
                <ul className="small">
                  <li>Tax Filing Of Form E</li>
                  <li>Personal Tax Computation Filing Of Form B, Be</li>
                  <li>Tax Filing Of Form C</li>
                  <li>Cp204 - Submission Of Tax Estimate</li>
                </ul>
              </Service>
            </div>
          </div>
        </div>
        <ServiceModal
          isOpen={modal}
          onRequestClose={() => setModal(false)}
          image={<Image source={modal.source} />}
          title={modal.title}
          body={modal.body}
        />

        <div
          id="contact-us"
          class="bg-light py-6 bg-cover"
          style={{
            background:
              "linear-gradient(0deg,rgba(248, 248, 248, 0.9),rgba(248, 248, 248, 0.9)), url('" +
              BgImgContactUs +
              "')",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-12 py-3">
                <h2 class="section-header">Contact Us</h2>
                <p class="section-subheader serif">Be in touch with us</p>
                <ContactForm />
              </div>
              <div class="col-md-6 col-12 py-3">
                <ContactInfo />
              </div>
            </div>
          </div>
        </div>

        <div id="faq" class="bg-light py-6">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <h2 class="section-header">Still Have Question?</h2>
                <p class="section-subheader serif mb-5">
                  Frequently Asked Questions
                </p>
                <FAQList />
              </div>
            </div>
          </div>
        </div>

        <div class="bg-black py-4">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-8 py-3">
                <p class="h4 serif">
                  Contact us today for more details how we been helping business
                  to operate efficiently
                </p>
              </div>
              <div class="col-12 col-md-4 py-4">
                <Scroll type="id" element="contact-us" offset={-60}>
                  <a class="btn btn-white d-block" href="#contact-us">
                    Contact Us Now
                  </a>
                </Scroll>
              </div>
            </div>
          </div>
        </div>

        <div class="py-6">
          <div class="container">
            <div class="row">
              <div class="col-4">
                <Image source="fixedLogo" />
              </div>
              <div class="col-8 text-right">
                <ul id="footer-nav" class="nav justify-content-end">
                  <FooterNavLink target="usp" text="Why Choose Us" />
                  <FooterNavLink target="about-us" text="About Us" />
                  <FooterNavLink target="our-services" text="Our Services" />
                  <FooterNavLink target="contact-us" text="Contact Us" />
                  <FooterNavLink target="faq" text="FAQ" />
                </ul>
              </div>
              <div class="col-12 text-md-right text-center">
                <div class="py-3">
                  Copyright © {new Date().getFullYear()} WT Lim & Co. All rights
                  reserved.{" "}
                  <a
                    href="https://www.innovten.com.my"
                    class="text-dark"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Web design by innovten
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function UnqiueSellingPointBlock(props) {
  return (
    <div class="col-md-4 py-3 py-md-5">
      <p class="text-uppercase font-weight-bold" style={{ minHeight: "48px" }}>
        {props.title}
      </p>
      <p class="p">{props.details}</p>
    </div>
  );
}

function FooterNavLink(props) {
  return (
    <li class="nav-item">
      <Scroll type="id" element={props.target} offset={-60}>
        <a class="nav-link" href={"#" + props.target}>
          {props.text}
        </a>
      </Scroll>
    </li>
  );
}

export const query = graphql`
  query {
    fixedLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fluidOffice: file(relativePath: { eq: "img-office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluidHero: file(relativePath: { eq: "bgimg-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluidCosec: file(relativePath: { eq: "bgimg-cosec.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluidAccounting: file(relativePath: { eq: "bgimg-accounting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fluidTax: file(relativePath: { eq: "bgimg-tax.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
